import * as yup from "yup";

const phoneRegExp =
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/;

export const SinemAppointmentRequestFormSchema = yup.object({
  patientSex: yup.string().required("Merci de renseigner une civilité"),
  patientFirstName: yup.string().required("Merci de renseigner un prénom"),
  patientLastName: yup
    .string()
    .required("Merci de renseigner un nom de famille"),
  patientPhone: yup
    .string()
    .matches(phoneRegExp, "Numéro non valide")
    .required("Merci de renseigner un nom de famille"),
  patientEmail: yup
    .string()
    .email("Merci d'utiliser une adresse email valide")
    .required("l'adresse email est obligatoire"),
  patientBirthDate: yup
    .date()
    .required("Merci de renseigner une date de naissance"),
  patientHeight: yup.string().required("Merci de renseigner une taille"),
  patientWeight: yup.string().required("Merci de renseigner un poids"),
  site: yup.string().required("Merci de séléctioner un site"),
  examType: yup.string().required("Merci de renseigner un type d'examen"),
  exam: yup.string().required("Merci de renseigner un examen"),
  preferedDate: yup
    .string()
    .required("Merci de renseigner une préférence de date"),
  doctorRpps: yup.string().required("Merci de renseigner un docteur"),
  medicalInfo: yup.string(),
  patientAlreadyCame: yup.boolean(),
  resultViaEmail: yup.boolean(),
});

export type SinemAppointmentRequestFormValues = yup.InferType<
  typeof SinemAppointmentRequestFormSchema
>;
