import { serialize } from "../utils";

export class APiBaseService {
  protected baseUrl = process.env.REACT_APP_API_URL;

  public async get<T>(
    url: string,
    query?: { [key: string]: string | number },
    headers?: { [key: string]: string }
  ): Promise<T> {
    if (query) {
      if (!url.includes("?")) {
        url += "?";
      } else {
        url += "&";
      }
      url += serialize(query);
    }
    const response = await fetch(url, {
      method: "GET",
      ...(!!headers && { headers: new Headers(headers) }),
    });

    return response.json();
  }
  public async post<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "POST",
      body,
    });

    return response.json();
  }
  public async put<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "PUT",
      body,
    });

    return response.json();
  }
  public async patch<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "PATCH",
      body,
    });

    return response.json();
  }
  public async delete<T>(url: string): Promise<T> {
    const response = await fetch(url, {
      method: "DELETE",
    });

    return response.json();
  }
}
