import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SinemAppointmentRequestForm from "./sinem/SinemAppointmentRequestForm";

const AppointmentRequest = () => {
  const location = useLocation();

  const [clientName, setClientName] = useState("");
  const contactForms = useRef<{ [key: string]: React.ElementType }>({
    sinem: SinemAppointmentRequestForm,
  }).current;

  useEffect(() => {
    const search = location.search;
    const query = new URLSearchParams(search);
    const client = query.get("client") || "";
    setClientName(client);
  }, [location.search]);

  const _getAppointmentForm = (): React.ElementType | null => {
    const form = contactForms[clientName];

    return form;
  };

  const AppointmentForm = _getAppointmentForm();

  return AppointmentForm ? <AppointmentForm /> : <h1>Not found</h1>;
};

export default AppointmentRequest;
