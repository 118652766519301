import { Add } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { createRef } from 'react';

import { FileItem } from './FileItem';

interface Props {
	accept?: string;
	files: File[];
	onChange: (files: File) => void;
	onDelete: (files: File) => void;
	multiple?: boolean;
}

export const FileInput = ({ files, onChange, onDelete }: Props) => {
	const inputRef = createRef<HTMLInputElement>();

	const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			for (let index = 0; index < event.target.files.length; index++) {
				const file = event.target.files[index];
				if (file.size > 4194304) {
					alert('Max file size is 4Mo !');
					return false;
				}
			}
			if (event.target.files.length !== 0) {
				onChange(event.target.files[0]);
			}
		}
	};

	const handleClick = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.click();
		}
	};

	return (
		<Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
			<Grid item xs={12}>
				<input
					name=""
					type="file"
					onChange={handleFilesChange}
					ref={inputRef}
					style={{ display: 'none' }}
				/>
				{files.map((f, i) => (
					<FileItem file={f} onDelete={onDelete} key={i} />
				))}
				<Grid item xs={12} sx={{marginTop: '10px'}}>
					<IconButton
						onClick={handleClick}
						sx={{
							color: '#fff',
							borderRadius: '50%',
							background: '#00509E',
							'&:hover': {
								backgroundColor: '#00509E80',
							},
						}}>
						<Add />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};
