import { useEffect, useState } from "react";
import moment from "moment";
import { CreateAnswerDto, QuestionDto } from "../interfaces/api-interfaces";
import { useFormik } from "formik";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
    ,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type QuestionsProps = {
  question: QuestionDto;
  onAnswerChange?: (answer: CreateAnswerDto) => void;
  defaultValue?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Questions({
  question,
  onAnswerChange,
  defaultValue: defaultAnswer,
}: QuestionsProps) {
  const [isSecondaryQuestionVisible, setIsSecondaryQuestionVisible] =
    useState(false);
  const [secondaryQuestionAnswer, setSecondaryQuestionAnswer] =
    useState<CreateAnswerDto | null>(null);

  const {
    text,
    type,
    choices,
    childQuestion: secondaryQuestion,
    askSecondaryIf,
  } = question;

  const formik = useFormik<{
    answer?: string;
    choice?: boolean;
    date?: Date;
  }>({
    initialValues: {},
    onSubmit: (values) => {
      console.log({ values });
    },
  });

  useEffect(() => {
    let answer = formik.values.answer;

    if (type === "DATE") {
      answer = moment(answer).format("DD-MM-YYYY");
    }

    if (secondaryQuestion && answer === askSecondaryIf) {
      setIsSecondaryQuestionVisible(true);
    } else {
      setIsSecondaryQuestionVisible(false);
    }
    if (onAnswerChange) {
      const obj: CreateAnswerDto = {
        questionId: question.id,
        text: answer?.toString(),
        secondaryAnswer: secondaryQuestionAnswer?.text,
      };
      onAnswerChange(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.answer, secondaryQuestionAnswer]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
      }}
    >
      {type === "OPEN" ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" component="body">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="answer"
              name="answer"
              variant="outlined"
              onChange={formik.handleChange}
            />
          </Grid>
        </>
      ) : type === "CLOSED" ? (
        <>
          <Grid item xs={12}>
            <Typography color="primary" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={formik.values.answer}
              name="answer"
              onChange={formik.handleChange}
            >
              <FormControlLabel value="TRUE" control={<Radio />} label="Oui" />
              <FormControlLabel value="FALSE" control={<Radio />} label="Non" />
            </RadioGroup>
            {/* <Radio
              name="answer"
              defaultValue={defaultAnswer || undefined}
              options={[
                { label: "Oui", value: "TRUE" },
                { label: "Non", value: "FALSE" },
              ]}
            /> */}
          </Grid>
        </>
      ) : type === "DATE" ? (
        <>
          <Grid item xs={12}>
            <Typography
              color="primary"
              variant="body1"
              style={{ marginBottom: "5px" }}
            >
              {text}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={formik.values.answer}
                onChange={formik.handleChange}
                renderInput={(params) => <TextField
                     {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </>
      ) : type === "CHOICES" ? (
        <>
          <Grid item xs={12}>
            <Typography color="primary" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "10px",
            }}
          >
            <Select name="answer" variant="outlined">
              {choices.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </>
      ) : (
        ""
      )}
      {isSecondaryQuestionVisible && (
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Questions
            question={question.childQuestion}
            onAnswerChange={setSecondaryQuestionAnswer}
          />
        </Grid>
      )}
    </Grid>
  );
}
