import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import MedicationIcon from '@mui/icons-material/Medication';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import {
	Button,
	Divider,
	FormControlLabel,
	Grid,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { FileInput } from '../../../components/FileInput/FileInput';
import { RppsAutocompleteByNameInput } from '../../../components/inputs/RppsAutocompleteByNameInput';
import Questions from '../../../components/Questions';
import { CreateAnswerDto, RppsDto, Site } from '../../../interfaces/api-interfaces';
import { ExamService } from '../../../services/exam.service';
import { QuestionService } from '../../../services/question.service';
import { SiteService } from '../../../services/sites.service';
import {
	SinemAppointmentRequestFormSchema,
	SinemAppointmentRequestFormValues,
} from '../../../shared/schemas/sinem-apointment-form.schema';

const SinemAppointmentRequestForm = () => {
	const siteService = useRef(new SiteService()).current;
	const examService = useRef(new ExamService()).current;
	const questionService = useRef(new QuestionService()).current;
	const [files, setFiles] = useState<File[]>([]);
	const answersMap = useRef(new Map<number, CreateAnswerDto>()).current;

	const formik = useFormik<SinemAppointmentRequestFormValues>({
		initialValues: {
			patientSex: '',
			patientFirstName: '',
			patientLastName: '',
			patientPhone: '',
			patientEmail: '',
			patientBirthDate: new Date(),
			patientHeight: '',
			patientWeight: '',
			site: '',
			examType: '',
			exam: '',
			preferedDate: '',
			doctorRpps: '',
			medicalInfo: '',
			resultViaEmail: true,
			patientAlreadyCame: false,
		},
		validationSchema: SinemAppointmentRequestFormSchema,
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const handlePatientBirthDateChange = (date: Date | null) => {
		formik.setFieldValue('patientBirthDate', date);
	};

	const clientId = 4;
	const { data: sites } = useQuery<Site[]>('sites', () => siteService.find(clientId), {
		initialData: [],
	});

	const { data: examTypes } = useQuery({
		queryKey: ['examTypes', formik.values.site],
		queryFn: ({ queryKey }) => examService.findExamTypes(parseInt(queryKey[1])),
		initialData: [],
	});

	const { data: exams } = useQuery({
		queryKey: ['exams', formik.values.site, formik.values.examType],
		queryFn: ({ queryKey }) => {
			const siteId = isNaN(parseInt(queryKey[1])) ? undefined : parseInt(queryKey[1]);
			const examTypeId = isNaN(parseInt(queryKey[2])) ? undefined : parseInt(queryKey[2]);

			return examService.findExams(siteId, examTypeId);
		},
		initialData: { totalCounts: 0, datas: [] },
	});

	const { data: questions } = useQuery({
		queryKey: ['questions', formik.values.exam],
		queryFn: ({ queryKey }) => questionService.find(parseInt(queryKey[1])),
		initialData: [],
		enabled: !!formik.values.exam,
	});

	const siteOptions = sites?.map((site) => ({
		label: site.label,
		value: site.id,
	})) as { label: string; value: string | number }[];

	const examTypeOptions = examTypes?.map((type) => ({
		label: type.label,
		value: type.id.toString(),
	})) as { label: string; value: string }[];

	const examOptions = exams?.datas?.map((ex) => ({
		label: ex.label,
		value: ex.id.toString(),
	})) as { label: string; value: string }[];

	const instructions = [
		{ id: 1, label: 'A jeun 6H avant l’heure du RDV' },
		{ id: 2, label: 'Boire de l’eau plate à volonté (AUCUN sucres)' },
		{
			id: 3,
			label: 'Continuer vos traitements habituels (SAUF demande explicite sur la convocation)',
		},
		{ id: 4, label: 'Diabétique sous insuline : nous vous rappellerons' },
	];

	const handleRppsChange = (rpps: RppsDto | null) => {
		const { setFieldValue } = formik;
		setFieldValue('doctorRpps', rpps?.rppsNumber || '');
	};
	const handleFileChange = (file: File) => {
		setFiles([...files, file]);
	};
	const handleFileDelete = (file: File) => {
		setFiles(files.filter((f) => f !== file));
	};

	const handleAnswerChange = (answerDto: CreateAnswerDto) => {
		const key = answerDto.questionId;
		answersMap.set(key, answerDto);
		console.log(answersMap);
	};

	return (
		<Grid
			container
			spacing={1}
			justifyContent="center"
			alignItems="center"
			sx={{
				width: '100%',
				height: '100%',
				padding: '10px',
				backgroundColor: '#00509E10',
			}}>
			<Grid container xs={12} sx={{ height: '100%', padding: '20px', overflow: 'auto' }}>
				<Grid item xs={12}>
					<Divider>
						<ContactEmergencyIcon sx={{ marginBottom: '-10px', color: '#00509E' }} />
						<Typography variant="h6" component="h6" sx={{ color: '#00509E' }}>
							Docteur
						</Typography>
					</Divider>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Rechercher un prescripteur*
					</Typography>
					<Grid item xs={12}>
						<Typography style={{ color: 'grey', fontSize: '12px' }}>
							Merci de ne sélectionner qu'un seul résultat. (ex: John Doe 75)
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RppsAutocompleteByNameInput onChange={handleRppsChange} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider>
						<PersonIcon sx={{ marginBottom: '-10px', color: '#00509E' }} />
						<Typography variant="h6" component="h6" sx={{ color: '#00509E' }}>
							Patient
						</Typography>
					</Divider>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Civilité*
					</Typography>
					<RadioGroup
						aria-labelledby="genderSelectionRadioButtons"
						name="patientSex"
						value={formik.values.patientSex}
						onChange={formik.handleChange}
						row>
						<FormControlLabel value="female" control={<Radio />} label="Mme/Mlle" />
						<FormControlLabel value="male" control={<Radio />} label="Mr" />
					</RadioGroup>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Prénom*
					</Typography>
					<TextField
						name="patientFirstName"
						label="Prénom du patient"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Nom de famille*
					</Typography>
					<TextField
						name="patientLastName"
						label="Nom de famille du patient"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Téléphone*
					</Typography>
					<TextField
						name="patientPhone"
						label="Mobile uniquement, un code de verrification vous sera envoyé pour valider votre
						demande"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Email*
					</Typography>
					<TextField
						name="patientEmail"
						label="Adresse email valide"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Date de naissance*
					</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							inputFormat="MM/DD/YYYY"
							value={formik.values.patientBirthDate}
							onChange={handlePatientBirthDateChange}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Lieu de naissance*
					</Typography>
					<TextField
						name="patientBirthPlace"
						label="Lieu de naissance"
						variant="outlined"
						fullWidth
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Souhaitez-vous recevoir vos résultat par email / en ligne?
					</Typography>
					<RadioGroup
						aria-labelledby="resultViaEmailSelectionRadioButtons"
						name="resultViaEmail"
						value={formik.values.resultViaEmail}
						onChange={formik.handleChange}
						row>
						<FormControlLabel value={true} control={<Radio />} label="Oui" />
						<FormControlLabel value={false} control={<Radio />} label="Non" />
					</RadioGroup>
				</Grid>

				<Grid item xs={12} sx={{ marginTop: '5px' }}>
					<Divider>
						<MedicationIcon sx={{ marginBottom: '-10px', color: '#00509E' }} />
						<Typography variant="h6" component="h6" sx={{ color: '#00509E' }}>
							Examen
						</Typography>
					</Divider>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" component="h6">
						Etablissement*
					</Typography>
					<Select name="site" value={formik.values.site} fullWidth onChange={formik.handleChange}>
						{siteOptions.map((site) => (
							<MenuItem key={site.value} value={site.value}>
								{site.label}
							</MenuItem>
						))}
					</Select>
				</Grid>
				{formik.values.site && (
					<>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Etes-vous déjà venu dans l'établissement ?
							</Typography>

							<RadioGroup
								aria-labelledby="patientAlreadyCameSelectionRadioButtons"
								name="patientAlreadyCame"
								value={formik.values.patientAlreadyCame}
								onChange={formik.handleChange}
								row>
								<FormControlLabel value={true} control={<Radio />} label="Oui" />
								<FormControlLabel value={false} control={<Radio />} label="Non" />
							</RadioGroup>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Type d'examen*
							</Typography>
							<Select
								name="examType"
								value={formik.values.examType}
								fullWidth
								onChange={formik.handleChange}>
								{examTypeOptions.map((examType) => (
									<MenuItem key={examType.value} value={examType.value}>
										{examType.label}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</>
				)}
				{formik.values.examType && (
					<Grid item xs={12}>
						<Typography variant="h6" component="h6">
							Examen*
						</Typography>
						<Select name="exam" value={formik.values.exam} fullWidth onChange={formik.handleChange}>
							{examOptions.map((exam) => (
								<MenuItem key={exam.value} value={exam.value}>
									{exam.label}
								</MenuItem>
							))}
						</Select>
					</Grid>
				)}
				{formik.values.exam && (
					<Grid item xs={12}>
						<Typography variant="h6" component="h6">
							Période de rendez-vous souhaitée*
						</Typography>

						<Select
							name="preferedDate"
							value={formik.values.preferedDate}
							fullWidth
							onChange={formik.handleChange}>
							<MenuItem value={'emergency'}>Urgence</MenuItem>
							<MenuItem value={'-15days'}>-15 jours</MenuItem>
							<MenuItem value={'+15days'}>+15 jours</MenuItem>
							<MenuItem value={'specific'}>
								Spécifique{' '}
								<span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '5px' }}>
									(précisez)
								</span>
							</MenuItem>
						</Select>
					</Grid>
				)}
				{formik.values.preferedDate === 'specific' && (
					<Grid item xs={12} sx={{ marginTop: '10px' }}>
						<TextField
							name="preferedDateInfo"
							label="Contraintes / Préférences"
							placeholder="ex: Seulement les lundis"
							variant="outlined"
							multiline
							rows={4}
							fullWidth
						/>
					</Grid>
				)}
				{formik.values.exam && (
					<>
						<Grid item xs={12} sx={{ marginTop: '5px' }}>
							<Divider>
								<MedicationIcon sx={{ marginBottom: '-10px', color: '#00509E' }} />
								<Typography variant="h6" component="h6" sx={{ color: '#00509E' }}>
									Informations & Documents
								</Typography>
							</Divider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Taille* <span style={{ color: 'gray', fontSize: '0.8em' }}>(en cm)</span>
							</Typography>
							<TextField
								type="number"
								name="patientHeight"
								label="Taille"
								variant="outlined"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">cm</InputAdornment>,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Poids* <span style={{ color: 'gray', fontSize: '0.8em' }}>(en kg)</span>
							</Typography>
							<TextField
								type="number"
								name="patientWeight"
								label="Poids"
								variant="outlined"
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">kg</InputAdornment>,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							{questions?.map((_question) => (
								<Questions question={_question} onAnswerChange={handleAnswerChange} />
							))}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Informations Médicales
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="medicalInfo"
								label="Informations / Motifs"
								variant="outlined"
								multiline
								rows={4}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" component="h6">
								Inclure des fichiers
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FileInput files={files} onChange={handleFileChange} onDelete={handleFileDelete} />
						</Grid>
						<Grid item xs={12}></Grid>

						<Grid item xs={12} sx={{ marginTop: '5px' }}>
							<Divider>
								<Typography variant="h6" component="h6" sx={{ color: '#00509E' }}>
									Instructions à suivre
								</Typography>
							</Divider>
						</Grid>
						<Grid container sx={{ padding: '20px' }}>
							<Grid
								item
								xs={10}
								sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								{instructions?.map((_instruction) => (
									<Typography
										key={_instruction.id}
										variant="h6"
										component="p"
										sx={{
											':first-letter': { color: 'red', fontWeight: 'bold', fontSize: '1.2em' },
										}}>
										{_instruction.label}
									</Typography>
								))}
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: '15px' }}>
							<Button variant="contained" endIcon={<SendIcon />} fullWidth>
								Confirmer
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default SinemAppointmentRequestForm;
